<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  page: {
    title: "Create No Commission",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      valid: true,
      submitted: false,
      title: "Create No Commission",
      items: [
        {
          text: "No Commission",
          href: "/admin",
        },
        {
          text: "Create No Commission",
          active: true,
        },
      ],
      form: {
        currency: "",
        user_id: "",
      },
      allData: [],
      allData2: [],
    };
  },
  mounted() {
    this.getData();
  },
  validations: {
    form: {
      currency: {
        required,
      },
      user_id: {
        required,
      },
    },
  },
  methods: {
    async getData() {
      await ApiClass.getRequest("admin/fund/get", true).then((res) => {
        if (res.data.status_code == 1) {
          this.allData = res.data.data.users ?? [];
          this.allData2 = res.data.data.currencyList ?? [];
        }
      });
    },

    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      var obj = {
        user_id: this.form.user_id.id,
        currency: this.form.currency.currency,
      };

      var result = await ApiClass.postRequest(
        "admin/no_commission/create",
        true,
        obj
      );
      // console.log(result);
      this.submitted = false;
      if (result.data.status_code == "1") {
        this.submitted = false;
        this.form.user_id = this.form.currency = "";

        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      this.submitted = false;
      this.form.user_id = this.form.currency = "";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="save" @reset="onReset">
            <!-- Text Editor -->

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div>
                  <p class="card-title-desc mt-2">Select User</p>
                  <multiselect
                    v-model.trim="form.user_id"
                    track-by="id"
                    label="name"
                    :options="allData"
                  ></multiselect>
                </div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.user_id.required"
                >
                  User Field is required
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div>
                  <p class="card-title-desc mt-2">Select Currency</p>
                  <multiselect
                    v-model.trim="form.currency"
                    track-by="currency"
                    label="currency"
                    :options="allData2"
                  ></multiselect>
                </div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.currency.required"
                >
                  Currency Field is required
                </div>
              </div>
            </div>
            <b-button class="mt-3" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button
              class="mt-3 ml-3"
              style="margin-left: 10px"
              type="reset"
              variant="danger"
              >Reset</b-button
            >
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>